import {HomeOutlined, PoweroffOutlined} from "@ant-design/icons";
import {Breadcrumb, Layout, Menu, Space, Tooltip, Typography} from "antd";
import React, {ReactNode, useCallback, useMemo, useState} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "src/auth/auth.context";
import {RouteModel} from "src/models/route.model";
import AssetsPage from "src/pages/assets/Assets.page";
import DashboardPage from "src/pages/dashboard/Dashboard.page";
import ManualDepositsPage from "src/pages/manual-deposits/ManualDeposits.page";
import MovementsToAlpacaPage from "src/pages/movements-to-alpaca/MovementsToAlpaca.page";
import MovementsPage from "src/pages/movements/Movements.page";
import UsersPage from "src/pages/users/Users.page";
import {filterRoutes, transformMenuItems} from "src/utils/filter-routes.util";

const MENU_ITEMS: RouteModel[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    element: <DashboardPage/>,
    roles: ["finances", "operator"]
  },
  {
    path: "/assets",
    title: "Assets",
    element: <AssetsPage/>,
    roles: ["assets"]
  },
  {
    path: "/users",
    title: "Usuarios",
    element: <UsersPage/>,
    roles: ["operator", "vector"]
  },
  {
    path: "/money",
    title: "Dinero",
    routes: [
      {
        path: "/manual-deposits",
        title: "Depósitos manuales",
        element: <ManualDepositsPage/>,
        roles: []
      },
      {
        divider: true
      },
      {
        path: "/movements",
        title: "Depósitos",
        element: <MovementsPage/>,
        roles: ["finances", "operator"]
      },
      {
        path: "/movements-to-alpaca",
        title: "Envíos a Alpaca",
        element: <MovementsToAlpacaPage/>,
        roles: ["finances", "operator"]
      }
    ]
  }
]

const AppRouter: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {user, logout} = useAuth()
  const [selectedKeys, setSelectedKeys] = useState(location.pathname.split("/").filter(k => k))

  const renderRoutes = useCallback((routesList: RouteModel[], parentPath: string = ""): ReactNode => {
    return routesList.map(({path, element, routes}) => {
      const finalPath = parentPath + path
      if (!routes) return <Route key={finalPath} path={finalPath} element={element}/>
      return renderRoutes(routes, finalPath)
    })
  }, [])

  const filteredRoutes = useMemo(() => filterRoutes(MENU_ITEMS, user.roles ?? []), [user.roles])
  const menuItems = useMemo(() => transformMenuItems(filteredRoutes), [filteredRoutes])

  const handleMenuClick = ({keyPath}: any) => {
    navigate(keyPath.reverse().join("/"))
    setSelectedKeys(keyPath)
  }

  const getRouteBreadcrumbs = useMemo(() => {
    let routes = filteredRoutes
    const breadcrumbs = []

    for (let i = 0; i < selectedKeys.length; i++) {
      const curr = routes.find(({path}) => path === `/${selectedKeys[i]}`)

      if (curr) {
        if (curr.routes) routes = curr.routes
        breadcrumbs.push(curr.title)
      }
    }

    return breadcrumbs
  }, [filteredRoutes, selectedKeys])

  return (
      <Layout>
        <Layout.Header style={{
          backgroundColor: "#9071FF",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <img src="/images/logo-black.svg" alt="Logo" width={110}/>
          <Space>
            <Typography.Text style={{color: "#f9f9f9"}} strong>{user.name}</Typography.Text>
            <Tooltip title="Cerrar sesión">
              <PoweroffOutlined onClick={logout} style={{cursor: "pointer"}}/>
            </Tooltip>
          </Space>
        </Layout.Header>
        <Menu
          mode="horizontal"
          style={{
            backgroundColor: "#ffffff",
            paddingLeft: 30,
            paddingRight: 30,
            display: "flex",
            justifyContent: "center"
          }}
          selectedKeys={selectedKeys}
          onClick={handleMenuClick}
          items={menuItems}
        />
        <Layout.Content
          style={{backgroundColor: "#f9f9f9", padding: "16px 48px 0 48px"}}
        >
          <Breadcrumb
            style={{marginBottom: 24}}
            separator=">"
            items={[
              {title: <HomeOutlined />},
              ...(getRouteBreadcrumbs.map(title => ({title})))
            ]}
          />
          <Routes>
            {filteredRoutes.length ? <Route path="/" element={<Navigate to={filteredRoutes[0].path!} replace/>}/> : null}
            {renderRoutes(MENU_ITEMS)}
          </Routes>
        </Layout.Content>
      </Layout>
  )
}

export default AppRouter