import {Transform, Type} from "class-transformer";
import moment, {Moment} from "moment";

export class MovementModel {
  hash!: string

  amount!: number

  amountInUsd?: number

  usdToClp?: number

  reported?: boolean

  creasysStatus?: number

  status!: string

  userIdNumber!: string

  @Type(() => Date)
  @Transform(({value}) => moment(value))
  createdAt!: Moment
}