import {Dayjs} from "dayjs";
import React from "react";
import axios from "./axios";

export class AdminApi {
  static login = async (email: string, password: string) => {
    const response = await axios.post("/v1/auth/login", {email, password})
    return response.data
  }

  static refreshToken = async (refreshToken: string) => {
    const response = await axios.post("/v1/auth/refresh", {refreshToken})
    return response.data
  }

  static statsDateStatistics = async (statisticKey: string, type: string, date: Dayjs) => {
    const response = await axios.get(
    `/v1/stats/date-statistics?statistic_key=${statisticKey}&type=${type}&date=${date.format("YYYY-MM-DD")}`
    )
    return response.data
  }

  static codesCreate = async (code: string) => {
    const response = await axios.post(
      `/v1/codes/`,
      {code}
    )
    return response.data
  }

  static statsTotalStatistics = async (statisticKey: string) => {
    const response = await axios.get(
      `/v1/stats/total-statistics?statistic_key=${statisticKey}`
    )
    return response.data
  }

  static usersList = async (filters: any) => {
    let queryParams = []

    if (filters.status) queryParams.push(`status=${filters.status}`)
    if (filters.creasysStatus) queryParams.push(`creasysStatus=${filters.creasysStatus}`)
    if (filters.vectorStatus) queryParams.push(`vectorStatus=${filters.vectorStatus}`)
    if (filters.lastEvaluatedKey) queryParams.push(`last_evaluated_key=${filters.lastEvaluatedKey}`)



    const response = await axios.get(
      `/v1/users${queryParams.reduce((prev, curr) => prev ? `${prev}&${curr}` : `?${curr}`, "")}`
    )

    return response.data
  }

  static usersPending = async () => {
    const response = await axios.get("/v1/users/pending")
    return response.data
  }

  static usersGetBySearchType = async (searchValue: string, searchField?: string) => {
    const response = await axios.get(`/v1/users/${searchValue}?searchField=${searchField}`)
    return response.data
  }

  static usersVectorBlock = async (idNumber: string) => {
    const response = await axios.post(`/v1/users/vector/${idNumber}/block`)
    return response.data
  }

  static usersVectorApproveWithoutReason = async (idNumber: string) => {
    const response = await axios.post(`v1/users/vector/${idNumber}/approve-without-reason`)
    return response.data
  }

  static usersVectorApprove = async (
    idNumber: string, reason: string, riskLevel: string, matchLists: string[]
  ) => {
    const response = await axios.post(`v1/users/vector/${idNumber}/approve`, {
      reason,
      riskLevel,
      matchLists
    })
    return response.data
  }

  static usersVectorMassiveApprove = async (idNumbers: React.Key[]) => {
    const response = await axios.post(`v1/users/vector/massive-approve`, {
      idNumbers
    })
    return response.data
  }

  static movementsList = async (status: string, lastEvaluatedKey?: string) => {
    let queryParams = `status=${status}`
    if (lastEvaluatedKey) queryParams += `&last_evaluated_key=${lastEvaluatedKey}`

    const response = await axios.get(
      `/v1/movements?${queryParams}`
    )

    return response.data
  }

  static movementSummaries = async (status: string, lastEvaluatedKey?: string) => {
    let queryParams = `status=${status}`
    if (lastEvaluatedKey) queryParams += `&last_evaluated_key=${lastEvaluatedKey}`

    const response = await axios.get(
      `/v1/movements/summary?${queryParams}`
    )

    return response.data
  }

  static movementsApiCustomerOrders = async () => {
    const response = await axios.get(
      `/v1/movements/api-customer-orders`
    )

    return response.data
  }


  static assetBySymbol = async (symbol: string) => {
    const response = await axios.get(`/v1/assets/${symbol}`)
    return response.data
  }

  static assetsGetPresignedUrl = async (symbol: string) => {
    const response = await axios.get(`/v1/assets/${symbol}/get-presigned-url`)
    return response.data
  }

  static assetUpdateIcon = async (symbol: string) => {
    await axios.get(`/v1/assets/${symbol}/update-icon`)
  }

  static assetUpdateField = async (symbol: string, field: string, value: string) => {
    await axios.patch(`/v1/assets/${symbol}`, {
      field, value
    })
  }
}