import {Button, Descriptions, Drawer, Form, Input, message, Radio, Switch} from "antd";
import React, {useState} from "react";
import {useMutation} from "react-query";
import {AdminApi} from "src/apis/admin.api";

interface Props {
  userDetail: any,
  isOpen: boolean,
  close: () => void
}

const riskOptions = [
  {label: "Bajo", value: "LOW"},
  {label: "Medio", value: "MIDDLE"},
  {label: "Alto", value: "HIGH"}
]

const UserComplianceApproveDrawer: React.FC<Props> = ({
  userDetail, isOpen, close
}) => {
  const [reason, setReason] = useState("")
  const [riskLevel, setRiskLevel] = useState("LOW")
  const [matchLists, setMatchLists] = useState<Set<string>>(new Set())

  const approveMutation = useMutation({
    mutationFn: () =>
      AdminApi.usersVectorApprove(
        userDetail.idNumber,
        reason,
        riskLevel,
        Array.from<string>(matchLists)
      ),
    onSuccess: () => {
      message.success(`El usuario ${userDetail.idNumber} ha sido aprobado`)
      close()
    }
  })

  const filterApprove = async () => {
    if (!reason) {
      return message.error("Debes escribir un motivo para aprobar")
    }

    if (!riskLevel) {
      return message.error("Debes elegir un riesgo")
    }

    if (matchLists.size === 0) {
      return message.error("Debes elegir al menos 1 lista que hizo match")
    }

    await approveMutation.mutateAsync()
  }

  const changeMatchLists = (value: string, add: boolean) => {
    setMatchLists(curr => {
      const newSet = new Set(curr)

      if (add) {
        newSet.add(value)
      } else {
        newSet.delete(value)
      }

      return newSet
    })
  }

  return <Drawer
    title="Aprobar con observaciones"
    placement="right"
    open={isOpen}
    onClose={close}
    width="25%"
    extra={<Button loading={approveMutation.isLoading} type="primary" size="small" onClick={filterApprove}>Aprobar</Button>}
  >
    <Form
      layout="vertical"
    >
      <Form.Item label="Motivo" required>
        <Input.TextArea
          showCount
          autoSize={{minRows: 5, maxRows: 5}}
          value={reason}
          onChange={({target: {value}}) => setReason(value)}
          rows={5}
        />
      </Form.Item>
      <Form.Item label="Nivel de riesgo" required>
        <Radio.Group
          options={riskOptions}
          onChange={({target: {value}}) => setRiskLevel(value)}
          value={riskLevel}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>
      <Form.Item label="Listas que hicieron match" required>
        <Descriptions
          column={1}
          size="middle"
          bordered
        >
          <Descriptions.Item label="OFAF">
            <Switch
              checked={matchLists.has("OFAF")}
              onChange={(add: any) => changeMatchLists("OFAF", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="LISTA DE INTERÉS">
            <Switch
              checked={matchLists.has("LISTA_DE_INTERES")}
              onChange={(add: any) => changeMatchLists("LISTA_DE_INTERES", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="PAÍSES SANCIONADOS">
            <Switch
              checked={matchLists.has("PAISES_SANCIONADOS")}
              onChange={(add: any) => changeMatchLists("PAISES_SANCIONADOS", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="LISTA REGCHEQ">
            <Switch
              checked={matchLists.has("LISTA_REGCHEQ")}
              onChange={(add: any) => changeMatchLists("LISTA_REGCHEQ", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="INTERPOL">
            <Switch
              checked={matchLists.has("INTERPOL")}
              onChange={(add: any) => changeMatchLists("INTERPOL", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="PALABRAS CLAVES">
            <Switch
              checked={matchLists.has("PALABRAS_CLAVE")}
              onChange={(add: any) => changeMatchLists("PALABRAS_CLAVE", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="ONU">
            <Switch
              checked={matchLists.has("ONU")}
              onChange={(add: any) => changeMatchLists("ONU", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="PRÓFUGOS PDI">
            <Switch
              checked={matchLists.has("PROFUGOS_PDI")}
              onChange={(add: any) => changeMatchLists("PROFUGOS_PDI", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="RTP">
            <Switch
              checked={matchLists.has("RTP")}
              onChange={(add: any) => changeMatchLists("RTP", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="PERSONA POLITICAMENTE EXPUESTA (PEP)">
            <Switch
              checked={matchLists.has("PEP")}
              onChange={(add: any) => changeMatchLists("PEP", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="FUNCIONARIOS PÚBLICOS CHILE">
            <Switch
              checked={matchLists.has("FUNCIONARIOS_PUBLICOS_CHILE")}
              onChange={(add: any) => changeMatchLists("FUNCIONARIOS_PUBLICOS_CHILE", add)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="PODER JUDICIAL CHILE">
            <Switch
              checked={matchLists.has("PODER_JUDICIAL_CHILE")}
              onChange={(add: any) => changeMatchLists("PODER_JUDICIAL_CHILE", add)}
            />
          </Descriptions.Item>
        </Descriptions>
      </Form.Item>
    </Form>
  </Drawer>
}

export default UserComplianceApproveDrawer