import {Descriptions} from "antd";
import React from "react";

interface Props {
  user: any
}

const Summary: React.FC<Props> = ({user}) => {
  return (
    <>
      <Descriptions
        column={1}
        size="middle"
        bordered
        title="Datos básicos"
        style={{marginTop: 24}}
      >
        <Descriptions.Item labelStyle={{width: "40%"}}  label="Nombre">{user?.names}</Descriptions.Item>
        <Descriptions.Item label="Apellidos">{user?.surnames}</Descriptions.Item>
        <Descriptions.Item label="Número de identificación">{user?.idNumber}</Descriptions.Item>
        <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
        <Descriptions.Item label="Teléfono">{user?.phone}</Descriptions.Item>
      </Descriptions>
      
      <Descriptions
        style={{marginTop: 36}}
        column={1}
        title="Detalles"
        size="middle"
        bordered
      >
        <Descriptions.Item labelStyle={{width: "40%"}} label="Estado civil">{user?.maritalStatus}</Descriptions.Item>
        <Descriptions.Item label="Fecha de nacimiento">{user?.birthdate}</Descriptions.Item>
        <Descriptions.Item label="Sexo">{user?.gender}</Descriptions.Item>
        <Descriptions.Item label="Ocupación">{user?.occupation}</Descriptions.Item>
      </Descriptions>

      {user?.maritalStatus === "Married" ? <Descriptions
        style={{marginTop: 36}}
        column={1}
        title="Datos de la pareja"
        size="middle"
        bordered
      >
        <Descriptions.Item labelStyle={{width: "40%"}}  label="Tipo de matrimonio">{user?.marriageType}</Descriptions.Item>
        <Descriptions.Item label="Nombre">{user?.partnerName}</Descriptions.Item>
        <Descriptions.Item label="Fecha de nacimiento">{user?.partnerBirthdate}</Descriptions.Item>
        <Descriptions.Item label="Número de identificación">{user?.partnerIdNumber}</Descriptions.Item>
      </Descriptions> : null}
    </>
  )
}

export default Summary