import {Transform, Type } from "class-transformer";
import moment from "moment";
import {Moment} from "moment";
import React from "react";

export class ListUserModel {
  key!: React.Key

  idNumber!: string

  email!: string

  @Type(() => Date)
  @Transform(({value}) => moment(value))
  createdAt!: Moment

  @Type(() => Date)
  @Transform(({value}) => moment(value))
  registeredInCreasysAt!: Moment

  @Transform(({value}) => moment(value))
  birthdate?: Moment

  creasysStatus!: string

  vectorStatus!: string

  alpacaStatus!: string

  status!: string
}