import {Button, Card, Col, Layout, Row, Select, Space, Table, Tag, Typography} from "antd";
import {ColumnsType} from "antd/es/table";
import {Moment} from "moment";
import React, {ReactNode, useEffect, useState} from "react";
import {useQuery} from "react-query";
import {AdminApi} from "src/apis/admin.api";
import {MovementSummaryModel} from "src/models/movement-summary.model";
import {formatToCurrencyUtil} from "src/utils/format-to-currency.util";
import {plainToInstanceMapUtil} from "src/utils/plain-to-instance-map.util";

const renderStatus: Record<string, ReactNode> = {
  delivered: <Tag color="green">Entregado</Tag>,
  on_the_way: <Tag color="yellow">En Camino</Tag>
}

const MovementsToAlpacaPage: React.FC = () => {
  const [movements, setMovements] = useState<Map<string, MovementSummaryModel>>(new Map())
  const [status, setStatus] = useState("on_the_way")
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined)

  const query = useQuery(
    ["listMovements", status, lastEvaluatedKey],
    () => AdminApi.movementSummaries(status, lastEvaluatedKey)
  )

  useEffect(() => {
    if (query.data?.movementSummaries) {
      const newMovements = plainToInstanceMapUtil({
        key: "sentAt",
        instanceClass: MovementSummaryModel,
        objectArray: query.data.movementSummaries
      })
      setMovements(curr => new Map([...curr, ...newMovements]))
    }
  }, [query.data])

  const loadMore = () => setLastEvaluatedKey(query.data?.lastEvaluatedKey)

  const handleStatusChange = (value: string) => {
    setStatus(value)
    setLastEvaluatedKey(undefined)
    setMovements(new Map())
  }

  const columns: ColumnsType<MovementSummaryModel> = [
    {
      title: "Enviado",
      dataIndex: "sentAt",
      render: (sentAt: Moment) => sentAt.format("DD-MM-YYYY HH:MM")
    },
    {
      title: "Monto enviado",
      dataIndex: "amountTransferred",
      align: "right",
      render: (balanceToTransfer: number) => formatToCurrencyUtil(balanceToTransfer, "USD")
    },
    {
      title: "Monto depósitos",
      dataIndex: "balanceToTransfer",
      align: "right",
      render: (balanceToTransfer: number) => formatToCurrencyUtil(balanceToTransfer, "USD")
    },
    {

      title: "Depósitos rechazados",
      dataIndex: "rejectedMovements",
      align: "right",
      render: (rejectedMovements: string[]) => rejectedMovements.length
    },
    {

      title: "Depósitos válidos",
      dataIndex: "validMovements",
      align: "right",
      render: (validMovements: string[]) => validMovements.length
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (status: string) => renderStatus[status]
    }
  ]

  return (
    <Layout style={{backgroundColor: "transparent"}}>
      <Space direction="vertical" size="large">
        <Row gutter={16}>
          <Col span={24} sm={12} md={8}>
            <Card title="Estado" size="small">
              <Tag.CheckableTag
                onClick={() => handleStatusChange("on_the_way")}
                checked={status === "on_the_way"}
              >En camino</Tag.CheckableTag>
              <Tag.CheckableTag
                onClick={() => handleStatusChange("delivered")}
                checked={status === "delivered"}
              >Entregados</Tag.CheckableTag>
            </Card>
          </Col>
        </Row>
      <Table
        rowKey="sentAt"
        bordered
        sticky
        dataSource={Array.from(movements.values())}
        columns={columns}
        pagination={false}
        {...(query.data?.lastEvaluatedKey ? {footer: () => <Button onClick={loadMore} type="link">Cargar más</Button>} : {})}
      />
      </Space>
    </Layout>
  )
}

export default MovementsToAlpacaPage