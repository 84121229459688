import {ConfigProvider} from "antd";
import React, {PropsWithChildren} from 'react'
import { QueryClient, QueryClientProvider } from 'react-query';
import {BrowserRouter} from "react-router-dom";
import {useAuth} from "src/auth/auth.context";
import AppRouter from "src/pages/AppRouter";
import LoginPage from "src/pages/login/Login.page";

const queryClient = new QueryClient()

const App: React.FC<PropsWithChildren> = ({children}) => {
  const {isAuthenticated} = useAuth()

  if (isAuthenticated) {
    return (
      <ConfigProvider
        theme={{token: {colorPrimary: "#9071FF"}}}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AppRouter/>
          </BrowserRouter>
        </QueryClientProvider>
      </ConfigProvider>
    )
  }

  return <LoginPage/>
}
export default App;
