import {Transform, Type} from "class-transformer";
import moment, {Moment} from "moment";

export class MovementSummaryModel {
  status!: string

  @Type(() => Date)
  @Transform(({value}) => moment(value))
  sentAt!: Moment

  amountTransferred?: number

  balanceToTransfer!: number

  rejectedMovements!: string[]

  validMovements!: string[]
}