import {EditOutlined} from "@ant-design/icons"
import {Button, Card, Col, Descriptions, Input, message, Row, Space, Tag, Upload} from "antd";
import axios from "axios"
import React, {useState} from "react";
import {useMutation} from "react-query";
import {AdminApi} from "src/apis/admin.api";

interface EditingFields {
  [field: string]: boolean
}

interface FieldsValue {
  [field: string]: string
}

const AssetDetails: React.FC = (details: any) => {
  const [isUploading, setIsUploading] = useState(false)
  const [isEditing, setIsEditing] = useState<EditingFields>({
    symbolToShow: false, description: false
  })
  const [fieldValue, setFieldValue] = useState<FieldsValue>({
    symbolToShow: details.symbolToShow,
    description: details.description
  })

  const toggleEditField = (field: "symbolToShow" | "description") => {
    setIsEditing((curr: EditingFields) => {
      const editField = !curr[field]
      if (editField) setFieldValue((curr: FieldsValue) => ({...curr, [field]: details[field]}))
      return {...curr, [field]: editField}
    })
  }

  const changeFieldValue = (field: "symbolToShow" | "description", value: string) => {
    setFieldValue((curr: FieldsValue) => ({
      ...curr,
      [field]: value
    }))
  }

  const mutationEditField = useMutation({
    mutationFn: (field: string) => AdminApi.assetUpdateField(
      details.symbol, field, fieldValue[field]
    )
  })

  return (
    <Card>
      <Row>
        <Col span={6} style={{display: "flex", justifyContent: "center"}}>
          <Space direction="vertical" align="center" size={20}>
            <img width={100} height={100} src={details.icon} alt={details.symbol}/>
            {details.icon?.startsWith("data:image/png;base64") ? <Tag color="#111111">base64</Tag> : null}
            <Upload
              beforeUpload={async file => {
                const isPNG = file.type === "image/png"
                if (!isPNG) {
                  message.error(`${file.name} no es una imagen png`)
                  return false
                }
                return isPNG || Upload.LIST_IGNORE
              }}
              customRequest={async ({file}) => {
                try {
                  setIsUploading(true)
                  const presignedUrl = await AdminApi.assetsGetPresignedUrl(details.symbol!)
                  await axios.put(presignedUrl.url, file, {
                    headers: {
                      "Content-Type": "image/png",
                    }
                  })
                  await AdminApi.assetUpdateIcon(details.symbol!)
                } catch {
                  message.error("Problemas al subir el ícono")
                } finally {
                  setIsUploading(false)
                  details.refresh()
                }}
              }
              multiple={false}
              showUploadList={false}
              disabled={isUploading}
            >
              <Button type="link" size="small" icon={<EditOutlined />}>Cambiar</Button>
            </Upload>
          </Space>
        </Col>
        <Col span={18}>
          <Descriptions column={1} size="small" bordered>
            <Descriptions.Item label="Nombre">{details.name}</Descriptions.Item>
            <Descriptions.Item label="Símbolo">{details.symbol}</Descriptions.Item>
            <Descriptions.Item label="Símbolo a mostrar">
              {isEditing.symbolToShow ?
                <Space.Compact size="small">
                  <Input
                    value={fieldValue.symbolToShow}
                    onChange={({target: {value}}) => changeFieldValue("symbolToShow", value)}
                  />
                  <Button onClick={() => toggleEditField("symbolToShow")}>Cancelar</Button>
                  <Button
                    type="primary"
                    onClick={ async () => {
                      await mutationEditField.mutateAsync("symbolToShow")
                      toggleEditField("symbolToShow")
                      details.refresh()
                    }}
                  >
                    Guardar
                  </Button>
                </Space.Compact>
                :
                <Space>
                  {details.symbolToShow ?? details.symbol}
                  <Button
                    size="small"
                    onClick={() => toggleEditField("symbolToShow")}
                    type="link"
                    icon={<EditOutlined/>}
                  />
                </Space>
              }
            </Descriptions.Item>
            <Descriptions.Item label="Tipo">{details.assetType}</Descriptions.Item>
            <Descriptions.Item
              labelStyle={{verticalAlign: "text-top"}}
              contentStyle={{width: 550}}
              label="Descripción"
            >
              {isEditing.description ?
                <Space direction="vertical" style={{width: "100%"}}>
                  <Input.TextArea
                    style={{width: 500}}
                    value={fieldValue.description}
                    onChange={({target: {value}}) => changeFieldValue("description", value)}
                    autoSize={{minRows: 4, maxRows: 20}}
                  />
                  <Space>
                    <Button size="small" onClick={() => toggleEditField("description")}>
                      Cancelar
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      onClick={ async () => {
                        await mutationEditField.mutateAsync("description")
                        toggleEditField("description")
                        details.refresh()
                      }}
                    >
                      Guardar
                    </Button>
                  </Space>
                </Space>
                :
                <>{details.description} <Button onClick={() => toggleEditField("description")} size="small" type="link" icon={<EditOutlined />}/></>
              }
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Card>
  )
}

export default AssetDetails