import {Button, Card, Col, Input, Layout, message, Row, Space, Table, Tag, Typography} from "antd";
import {ColumnsType} from "antd/es/table";
import {Moment} from "moment";
import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {AdminApi} from "src/apis/admin.api";
import {useAuth} from "src/auth/auth.context";
import {Filters} from "src/models/filters.model";
import {ListUserModel} from "src/models/list-user.model";
import UserDetailDrawer from "src/pages/users/UserDetail.drawer";
import UsersLimboModal from "src/pages/users/users-limbo-modal/UsersLimboModal";
import {renderCreasysStatus, renderStatus} from "src/pages/users/utils";
import {plainToInstanceMapUtil} from "src/utils/plain-to-instance-map.util";
import {hasRole} from "src/utils/roles.util";


interface UsersFilters extends Filters {
  status?: string
  vectorStatus?: string
  creasysStatus?: string
  lastEvaluatedKey?: string
}

const UsersPage: React.FC = () => {
  const {user} = useAuth()
  const hasComplianceRole = hasRole(user, "vector")
  const [inputByIdNumber, setInputByIdNumber] = useState("")
  const [inputByEmail, setInputByEmail] = useState("")
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const [isLimboOpen, setIsLimboOpen] = useState(false)
  const [userSearch, setUserSearch] = useState({
    searchType: "idNumber",
    searchValue: ""
  })
  const [users, setUsers] = useState<Map<string, ListUserModel>>(new Map())
  const [selectedUsers, setSelectedUsers] = useState<React.Key[]>([])
  const [filters, setFilters] = useState<UsersFilters>({
    status: "In Review",
    vectorStatus: "Created",
    creasysStatus: "Created"
  })

  const query = useQuery(
    ["listUsers", JSON.stringify(filters)],
    () => AdminApi.usersList(filters)
  )

  useEffect(() => {
    if (query.data?.users) {
      const newUsers = plainToInstanceMapUtil({
        key: "idNumber",
        instanceClass: ListUserModel,
        objectArray: query.data.users
      })
      setUsers(curr => new Map([...curr, ...newUsers]))
    }
  }, [query.data])

  const loadMore = useCallback(() => {
    if (query.data) setFilters(curr => ({
      ...curr, lastEvaluatedKey: query.data.lastEvaluatedKey
    }))
  }, [query])

  const handleStatusChange = (status?: string) => {
    if (status !== filters.status) {
      setUsers(new Map())
      setFilters(filters => ({...filters, lastEvaluatedKey: undefined, status}))
    }
  }

  const handleCreasysStatusChange = (creasysStatus?: string) => {
    if (creasysStatus !== filters.creasysStatus) {
      setUsers(new Map())
      setFilters(filters => ({...filters, lastEvaluatedKey: undefined, creasysStatus}))
    }
  }

  const handleVectorStatusChange = (vectorStatus?: string) => {
    if (vectorStatus !== filters.vectorStatus) {
      setUsers(new Map())
      setFilters(filters => ({...filters, lastEvaluatedKey: undefined, vectorStatus}))
    }
  }

  const columns: ColumnsType<ListUserModel> = [
    {
      title: "# de identificación",
      dataIndex: "idNumber"
    },
    {
      title: "Email",
      dataIndex: "email"
    },
    {
      title: "Fecha de registro",
      dataIndex: "registeredInCreasysAt",
      defaultSortOrder: "descend",
      render: (createdAt: Moment) => createdAt.format("DD-MM-YYYY")
    },
    {
      title: "Estado en Vector",
      dataIndex: "vectorStatus",
      render: (status: string) => renderCreasysStatus[status]
    },
    {
      title: "Estado en Creasys",
      dataIndex: "creasysStatus",
      render: (status: string) => renderCreasysStatus[status]
    },
    {
      title: "Estado en Alpaca",
      dataIndex: "alpacaStatus",
      render: (status: string) => renderStatus[status]
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (status: string) => renderStatus[status]
    }
  ]

  const searchByIdNumber = () => {
    if (!inputByIdNumber) return
    setUserSearch({searchType: "idNumber", searchValue: inputByIdNumber})
    setIsDetailOpen(true)
  }

  const searchByEmail = () => {
    if (!inputByEmail) return
    setUserSearch({searchType: "email", searchValue: inputByEmail})
    setIsDetailOpen(true)
  }

  const massiveApproveMutation = useMutation({
    mutationFn: () => AdminApi.usersVectorMassiveApprove(selectedUsers),
    onSuccess: () => {
      message.success("Usuarios seleccionados aprobados")
      setSelectedUsers([])
      setUsers(new Map())
      query.refetch()
    }
  })

  return (
    <Layout style={{backgroundColor: "transparent"}}>
      <Space direction="vertical" size="large">
        <Typography.Title level={4}>Búsqueda</Typography.Title>
        <Row gutter={16}>
          <Col span={8}>
          <Card title="Buscar por # de identificación" size="small">
            <Space.Compact size="middle">
              <Input onPressEnter={searchByIdNumber} onChange={({target: {value}}) => setInputByIdNumber(value)} allowClear/>
              <Button type="primary" onClick={searchByIdNumber}>Buscar</Button>
            </Space.Compact>
          </Card>
          </Col>
          <Col span={8}>
          <Card title="Buscar por Email" size="small">
            <Space.Compact size="middle">
              <Input onPressEnter={searchByEmail} onChange={({target: {value}}) => setInputByEmail(value)} allowClear/>
              <Button type="primary" onClick={searchByEmail}>Buscar</Button>
            </Space.Compact>
          </Card>
          </Col>
          { hasRole(user, "master") ?
            <Col span={8}>
              <Card title="Otras listas" size="small">
                <Space.Compact size="middle">
                  <Button type="primary" onClick={() => setIsLimboOpen(true)}>Rezagados</Button>
                </Space.Compact>
              </Card>
            </Col>
            : null}
        </Row>
        <Typography.Title level={4}>Filtros</Typography.Title>
        <Row gutter={16}>
          <Col span={8}>
            <Card title="Estado en vector" size="small" bodyStyle={{justifyContent: "center"}}>
              <Tag.CheckableTag
                checked={filters.vectorStatus === "Created"}
                onClick={() => handleVectorStatusChange("Created")}
              >Pendiente</Tag.CheckableTag>
              <Tag.CheckableTag
                checked={filters.vectorStatus === "Approved"}
                onClick={() => handleVectorStatusChange("Approved")}
              >Vigente</Tag.CheckableTag>
              <Tag.CheckableTag
                checked={filters.vectorStatus === "Blocked"}
                onClick={() => handleVectorStatusChange("Blocked")}
              >Bloqueado</Tag.CheckableTag>
            </Card>
          </Col>
          <Col span={8}>
          <Card title="Estado en creasys" size="small" bodyStyle={{justifyContent: "center"}}>
            <Tag.CheckableTag
              checked={filters.creasysStatus === "Created"}
              onClick={() => handleCreasysStatusChange("Created")}
            >Pendiente</Tag.CheckableTag>
            <Tag.CheckableTag
              checked={filters.creasysStatus === "Approved"}
              onClick={() => handleCreasysStatusChange("Approved")}
            >Vigente</Tag.CheckableTag>
            <Tag.CheckableTag
              checked={filters.creasysStatus === "Blocked"}
              onClick={() => handleCreasysStatusChange("Blocked")}
            >Bloqueado</Tag.CheckableTag>
          </Card>
          </Col>
          <Col span={8}>
            <Card title="Estado" size="small">
              <Tag.CheckableTag
                checked={filters.status === "In Review"}
                onClick={() => handleStatusChange("In Review")}
              >En revisión</Tag.CheckableTag>
              <Tag.CheckableTag
                checked={filters.status === "Approved"}
                onClick={() => handleStatusChange("Approved")}
              >Aprobado</Tag.CheckableTag>
              <Tag.CheckableTag
                checked={filters.status === "Blocked"}
                onClick={() => handleStatusChange("Blocked")}
              >Bloqueado</Tag.CheckableTag>
            </Card>
          </Col>
        </Row>
        {hasComplianceRole && filters.vectorStatus === "Created" ?
          <Button
            type="primary"
            size="middle"
            onClick={() => massiveApproveMutation.mutateAsync()}
            disabled={selectedUsers.length === 0}
            loading={massiveApproveMutation.isLoading}
          >
            Aprobar selección {selectedUsers.length > 0 ? `(${selectedUsers.length})` : ""}
          </Button>
          : null}
        <Table
          rowKey="idNumber"
          bordered
          sticky
          dataSource={Array.from(users.values())}
          {...(hasComplianceRole && filters.vectorStatus === "Created" ? {rowSelection: {
            selectedRowKeys: selectedUsers,
            onChange: setSelectedUsers
          }} : {})}
          columns={columns}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              setUserSearch({searchType: "idNumber", searchValue: record.idNumber})
              setIsDetailOpen(true)
            }
          })}
        />
      </Space>
      {isDetailOpen ? <UserDetailDrawer
        isOpen={isDetailOpen}
        close={() => setIsDetailOpen(false)}
        searchType={userSearch.searchType}
        searchValue={userSearch.searchValue}
      /> : null}
      <UsersLimboModal isOpen={isLimboOpen} close={() => setIsLimboOpen(false)}/>
    </Layout>
  )
}

export default UsersPage