import {Card, Statistic} from "antd";
import {Dayjs} from "dayjs";
import React, {useMemo} from "react";
import {useQuery} from "react-query";
import {AdminApi} from "src/apis/admin.api";
import {formatToCurrencyUtil} from "src/utils/format-to-currency.util";

interface Props {
  title: string
  type: "daily" | "monthly" | "yearly"
  statisticKey: string
  date: Dayjs
  format?: "default" | "usd" | "clp"
}

const DateStatsCardComponent: React.FC<Props> = ({
  title, type, statisticKey, date, format = "default"
}) => {
  const query = useQuery(
    [statisticKey, type, date],
    () => AdminApi.statsDateStatistics(statisticKey, type, date),
    {refetchInterval: 60 * 1000}
  )

  const value = useMemo(() => {
    const v = query.data?.reduce((acc: number, {total}: any) => acc + total, 0)

    if (format === "usd") return formatToCurrencyUtil(v, "USD")
    if (format === "clp") return formatToCurrencyUtil(v, "CLP")
    return v
  }, [query.data])

  return (
    <Card>
      <Statistic
        title={title}
        value={value}
        loading={query.isLoading}
      />
    </Card>
  )
}

export default DateStatsCardComponent