import {Card, Statistic} from "antd";
import React from "react";
import {useQuery} from "react-query";
import {AdminApi} from "src/apis/admin.api";

const UsersWithDepositsCardComponent: React.FC = () => {
  const query = useQuery(
    "usersWithDeposits",
    () => AdminApi.statsTotalStatistics("usersWithDeposits"),
    {refetchInterval: 60 * 1000}
  )

  return (
    <Card>
      <Statistic
        title="Han depositado"
        value={query.data?.total}
        loading={!query.data}
      />
    </Card>
  )
}

export default UsersWithDepositsCardComponent