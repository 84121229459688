import {Col, DatePicker, Layout, Row, Typography} from "antd";
import React, {useState} from "react";
import dayjs from 'dayjs'
import ApprovedUsersCardComponent from "src/pages/dashboard/components/ApprovedUsersCard/ApprovedUsersCard.component";
import DateStatsCardComponent from "src/pages/dashboard/components/DateStatsCard/DateStatsCard.component";
import NewUsersCardComponent from "src/pages/dashboard/components/NewUsersCard/NewUsersCard.component";
import UsersWithDepositsCardComponent
  from "src/pages/dashboard/components/UsersWithDepositsCard/UsersWithDepositsCard.component";
import locale from 'antd/es/date-picker/locale/es_ES';

const DashboardPage: React.FC = () => {
  const [date, setDate] = useState(dayjs())

  const handleSetDate = (value: any) => setDate(value)

  return (
    <Layout style={{backgroundColor: "transparent"}}>
      <Typography.Title level={2}>Dashboard</Typography.Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8} xl={6} xxl={4}>
          <NewUsersCardComponent/>
        </Col>
        <Col xs={24}  sm={8} xl={6} xxl={4}>
          <ApprovedUsersCardComponent/>
        </Col>
        <Col xs={24}  sm={8} xl={6} xxl={4}>
          <UsersWithDepositsCardComponent/>
        </Col>
      </Row>
      <Typography.Title level={2} style={{marginTop: 24}}>Estadísticas por día o mes</Typography.Title>
      <DatePicker
        style={{width: 200, marginBottom: 24}}
        value={date}
        onChange={handleSetDate}
        allowClear={false}
        locale={locale}
      />
      <Row gutter={[16, 16]} style={{marginBottom: 24}}>
        <Col xs={24} sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title="Registros este día" statisticKey="newUsers" type="daily" date={date}/>
        </Col>
        <Col xs={24}  sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title={`Registros en ${date.format("MMMM")}`} statisticKey="newUsers" type="monthly" date={date}/>
        </Col>
        <Col xs={24}  sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title={`Registros el año ${date.format("YYYY")}`} statisticKey="newUsers" type="yearly" date={date}/>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{marginBottom: 24}}>
        <Col xs={24} sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title="Comisiones este día" statisticKey="commissions" type="daily" date={date} format="usd"/>
        </Col>
        <Col xs={24}  sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title={`Comisiones en ${date.format("MMMM")}`} statisticKey="commissions" type="monthly" date={date} format="usd"/>
        </Col>
        <Col xs={24}  sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title={`Comisiones el año ${date.format("YYYY")}`} statisticKey="commissions" type="yearly" date={date} format="usd"/>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{marginBottom: 24}}>
        <Col xs={24} sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title="Spread compra este día" statisticKey="buySpread" type="daily" date={date} format="clp"/>
        </Col>
        <Col xs={24}  sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title={`Spread compra en ${date.format("MMMM")}`} statisticKey="buySpread" type="monthly" date={date} format="clp"/>
        </Col>
        <Col xs={24}  sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title={`Spread compra el año ${date.format("YYYY")}`} statisticKey="buySpread" type="yearly" date={date} format="clp"/>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{marginBottom: 24}}>
        <Col xs={24} sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title="Spread venta este día" statisticKey="sellSpread" type="daily" date={date} format="clp"/>
        </Col>
        <Col xs={24}  sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title={`Spread venta en ${date.format("MMMM")}`} statisticKey="sellSpread" type="monthly" date={date} format="clp"/>
        </Col>
        <Col xs={24}  sm={8} xl={6} xxl={4}>
          <DateStatsCardComponent title={`Spread venta el año ${date.format("YYYY")}`} statisticKey="sellSpread" type="yearly" date={date} format="clp"/>
        </Col>
      </Row>
    </Layout>
  )
}

export default DashboardPage