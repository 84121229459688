import {ClassConstructor, plainToInstance} from "class-transformer";

interface Props<T> {
  key: string
  instanceClass: ClassConstructor<T>
  objectArray: any[]
}

export const plainToInstanceMapUtil = <T>({
  key, instanceClass, objectArray
}: Props<T>): Map<typeof key ,T> => {
  return new Map(Object.entries(objectArray.reduce((acc, obj) => ({
    ...acc,
    [obj[key]]: plainToInstance(instanceClass, {...obj, key})
  }), {})))
}