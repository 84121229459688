import {Card, Statistic} from "antd";
import React from "react";
import {useQuery} from "react-query";
import {AdminApi} from "src/apis/admin.api";

const NewUsersCardComponent: React.FC = () => {
  const query = useQuery(
    "totalNewUsers",
    () => AdminApi.statsTotalStatistics("newUsers"),
    {refetchInterval: 60 * 1000}
  )


  return (
    <Card>
      <Statistic
        title="Usuarios totales"
        value={query.data?.total}
        loading={!query.data}
      />
    </Card>
  )
}

export default NewUsersCardComponent