import {Layout, Result} from "antd";
import React from "react";

const ManualDepositsPage: React.FC = () => {
  return (
    <Layout style={{backgroundColor: "transparent"}}>
      <Result
        status="404"
        title="Aún no disponible"
        subTitle="Lo sentimos, pronto estará disponible esta sección."
      />
    </Layout>
  )
}

export default ManualDepositsPage