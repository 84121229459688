import axios from "axios";
import {ACCESS_TOKEN, REFRESH_TOKEN} from "src/auth/constants";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://p5zpalf5q6.execute-api.us-east-2.amazonaws.com"
})

instance.interceptors.request.use(config => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN)
  if (accessToken) config.headers!["Authorization"] = `Bearer ${accessToken}`
  return config
}, error => Promise.reject(error))

// Refresh token login

let isRefreshing: boolean = false
let failedQueue: any[] = []

const processFailedQueue = (error: any, token = null) => {
  failedQueue.forEach(promise => {
    if (error) {
      promise.reject(error)
    } else {
      promise.resolve(token)
    }
  })
  failedQueue = []
}

instance.interceptors.response.use(response => {
  return response
}, error => {
  const originalRequest = error.config

  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({resolve, reject})
      }).then(token => {
        originalRequest.headers["Authorization"] = `Bearer ${token}`
        return instance(originalRequest)
      }).catch(error => {
        return Promise.reject(error)
      })
    }

    originalRequest._retry = true
    isRefreshing = true

    const refreshToken = localStorage.getItem(REFRESH_TOKEN)
    return new Promise((resolve, reject) => {
      instance.post("/v1/auth/refresh", {refreshToken})
        .then(({data}) => {
          localStorage.setItem(ACCESS_TOKEN, data.accessToken)
          localStorage.setItem(REFRESH_TOKEN, data.refreshToken)
          instance.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`
          processFailedQueue(null, data.accessToken)
          resolve(instance(originalRequest))
        })
        .catch(error => {
          processFailedQueue(error)
          reject(error)
        })
        .finally(() => {
          isRefreshing = false
        })
    })
  }
  return Promise.reject(error)
})

export default instance