import {CheckCircleFilled, CloseCircleFilled, MinusSquareFilled} from "@ant-design/icons";
import {Modal, Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import moment, {Moment} from "moment";
import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {AdminApi} from "src/apis/admin.api";
import {ListUserModel} from "src/models/list-user.model";
import {plainToInstanceMapUtil} from "src/utils/plain-to-instance-map.util";

interface Props {
  isOpen: boolean
  close: () => void
}


const UsersLimboModal: React.FC<Props> = ({isOpen, close}) => {
  const [users, setUsers] = useState<Map<string, ListUserModel>>(new Map())

  const query = useQuery(
    ["usersPending"],
    () => AdminApi.usersPending(),
    {
      ...(isOpen ? {refetchInterval: 1000 * 60} : {})
    }
  )

  useEffect(() => {
    if (query.data) {
      const newUsers = plainToInstanceMapUtil({
        key: "idNumber",
        instanceClass: ListUserModel,
        objectArray: query.data
      })
      setUsers(curr => new Map([...curr, ...newUsers]))
    }
  }, [query.data])

  const columns: ColumnsType<ListUserModel> = [
    {
      title: "# de identificación",
      dataIndex: "idNumber"
    },
    {
      title: "Email",
      dataIndex: "email"
    },
    {
      title: "Mayor de edad",
      dataIndex: "birthdate",
      align: "center",
      render: (birthdate: Moment) => {
        const isValidAge = moment().diff(birthdate, 'years') >= 18
        return isValidAge ? <CheckCircleFilled style={{color: "green"}} /> : <CloseCircleFilled style={{color: "red"}} />
      }
    },
    {
      title: "Correo verificado",
      dataIndex: "emailVerified",
      align: "center",
      render: (emailVerified) => emailVerified ? <CheckCircleFilled style={{color: "green"}} /> : <CloseCircleFilled style={{color: "gray"}}/>
    },
  ]

  return (
    <Modal open={isOpen} onCancel={close} footer={null} width={1000} destroyOnClose title="Usuarios rezagados">
      <Table
        size="small"
        rowKey="idNumber"
        columns={columns}
        dataSource={Array.from(users.values())}
        bordered
        pagination={false}
      />
    </Modal>
  )
}

export default UsersLimboModal