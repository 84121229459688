import {Button, Card, Input, Layout, message, Result, Space, Typography} from "antd";
import React, {useState} from "react";
import {useMutation} from "react-query";
import {AdminApi} from "src/apis/admin.api";
import AssetDetails from "src/pages/assets/AssetDetails";

const AssetsPage: React.FC = () => {
  const [symbol, setSymbol] = useState("")

  const assetSearchMutation = useMutation({
    mutationFn: () => AdminApi.assetBySymbol(symbol)
  })

  const search = async () => {
    if (!symbol) {
      return message.error("Debes ingresar un símbolo a buscar")
    }
    return assetSearchMutation.mutateAsync()
  }

  return (
    <Layout style={{backgroundColor: "transparent"}}>
      <Typography.Title level={2}>Assets</Typography.Title>
      <Space direction="vertical">
        <Card title="Buscar asset por símbolo" size="small">
          <Space.Compact size="middle">
            <Input
              onChange={({target: {value}}) => setSymbol(value)}
              value={symbol}
              allowClear
            />
            <Button
              type="primary"
              loading={assetSearchMutation.isLoading}
              onClick={search}
            >
              Buscar
            </Button>
          </Space.Compact>
        </Card>

        {assetSearchMutation.isError ?
          <Result
            status="404"
            title="No encontrado"
            subTitle="El asset que buscas no existe"
          />
          :
          assetSearchMutation.isSuccess ?
            <AssetDetails {...assetSearchMutation.data} refresh={search}/>
              : null
        }
      </Space>
    </Layout>
  )
}

export default AssetsPage