import {Tag} from "antd";
import React, {ReactNode} from "react";

export const renderStatus: Record<string, ReactNode> = {
  Created: <Tag>Creado</Tag>,
  "In Review": <Tag>En revisión</Tag>,
  Approved: <Tag color="green">Aprobado</Tag>,
  Blocked: <Tag color="red">Bloqueado</Tag>
}

export const renderCreasysStatus: Record<string, ReactNode> = {
  Created: <Tag>Pendiente</Tag>,
  Approved: <Tag color="green">Vigente</Tag>,
  Blocked: <Tag color="red">Bloqueado</Tag>
}