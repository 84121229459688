import {Button, Col, Form, Input, Row, Space} from "antd";
import React, {useState} from "react";
import {useAuth} from "src/auth/auth.context";

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const {login} = useAuth()

  const handleLogin = async (values: any) => {
    const {email, password} = values
    setLoading(true)
    try {
      const result = await login(email, password)
      if (!result) setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row style={{height: "100vh"}} gutter={0}>
      <Col xs={24} sm={24} md={8} lg={6} xxl={5}>
        <div style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Space
            direction="vertical"
            size={36}
            style={{display: "flex", alignItems: "center"}}
          >
            <img alt="Logo zesty" src="/images/logo-black.svg"/>
            <Form
              layout="vertical"
              onFinish={handleLogin}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{required: true}]}
              >
                <Input/>
              </Form.Item>
              <Form.Item
                label="Contraseña"
                name="password"
                rules={[{required: true}]}
              >
                <Input.Password/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} block>
                  Entrar
                </Button>
              </Form.Item>
            </Form>
            {error && <p style={{color: "rgba(229, 55, 0, 1)"}}>
              Email y/o contraseña incorrectos
            </p>}
          </Space>
        </div>
      </Col>
      <Col sm={0} md={16} lg={18} xxl={19}>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: "url(/images/companies-bg.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%"
          }}
        />
      </Col>
    </Row>
  )
}

export default LoginPage