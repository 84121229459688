import {Button, Descriptions, Drawer, message, Result, Space, Spin, Tabs} from "antd";
import React, {useMemo, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {AdminApi} from "src/apis/admin.api";
import {useAuth} from "src/auth/auth.context";
import UserComplianceApproveDrawer from "src/pages/users/user-compliance/UserComplianceApprove.drawer";
import Summary from "src/pages/users/user-detail/Summary";
import {renderCreasysStatus, renderStatus} from "src/pages/users/utils";
import {hasRole} from "src/utils/roles.util";

interface Props {
  isOpen: boolean
  close: () => void
  searchType: string
  searchValue: string
}

const UserDetailDrawer: React.FC<Props> = ({
  isOpen, close, searchType, searchValue
}) => {
  const {user} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const hasComplianceRole = hasRole(user, "vector")
  const [isSubDrawerOpen, setIsSubDrawerOpen] = useState(false)

  const query = useQuery({
    queryFn: () => AdminApi.usersGetBySearchType(searchValue, searchType),
    retry: false
  })

  const blockMutation = useMutation({
    mutationFn: (idNumber: string) => AdminApi.usersVectorBlock(idNumber),
    onMutate: () => {
      setIsLoading(true)
    },
    onSettled: () => {
      setIsLoading(false)
    },
    onSuccess: () => {
      message.open({
        type: "error",
        content: `El usuario ${query.data.idNumber} ha sido bloqueado`
      })
      query.refetch()
    }
  })

  const approveWithoutReasonMutation = useMutation({
    mutationFn: (idNumber: string) => AdminApi.usersVectorApproveWithoutReason(idNumber),
    onMutate: () => {
      setIsLoading(true)
    },
    onSettled: () => {
      setIsLoading(false)
    },
    onSuccess: () => {
      message.open({
        type: "success",
        content: `El usuario ${query.data.idNumber} ha sido aprobado`
      })
      query.refetch()
    }
  })

  const closeDetailDrawer = () => {
    query.remove()
    close()
  }

  const vectorOptions = useMemo(() => {
    if (!query.data || !hasComplianceRole) return null

    return (
      <Space direction="horizontal">
        {query.data?.vectorStatus !== "Approved" ? <>
          <Button type="primary" loading={isLoading} size="small" onClick={() => setIsSubDrawerOpen(true)}>Aprobar con observaciones</Button>
          <Button type="primary" loading={isLoading} onClick={() => approveWithoutReasonMutation.mutateAsync(query.data.idNumber)} size="small">Aprobar</Button>
        </> : null}
        {query.data?.vectorStatus !== "Blocked" ?
          <Button type="primary" loading={isLoading} onClick={() => blockMutation.mutateAsync(query.data.idNumber)} size="small" danger>Bloquear</Button> : null}
      </Space>
    )
  }, [query.data, hasComplianceRole, isLoading])

  return <Drawer
    title={`Detalles del usuario (${searchValue})`}
    placement="right"
    open={isOpen}
    onClose={closeDetailDrawer}
    size="large"
    extra={vectorOptions}
    destroyOnClose
  >
    <Spin spinning={query.isLoading || isLoading}>
      {query.data ?
        <Space direction="vertical" size="large">
          <Descriptions
            column={2}
            size="small"
            bordered
          >
            <Descriptions.Item label="Nombre">{query.data?.names} {query.data?.surnames}</Descriptions.Item>
            <Descriptions.Item label="# de identificación">{query.data?.idNumber}</Descriptions.Item>
            <Descriptions.Item label="Email">{query.data?.email}</Descriptions.Item>
            <Descriptions.Item label="Teléfono">{query.data?.phone}</Descriptions.Item>
          </Descriptions>

          <Descriptions
            size="small"
            layout="vertical"
            column={4}
            colon={false}
          >
            <Descriptions.Item label="Estado en Vector">
              {renderCreasysStatus[query.data?.vectorStatus]}
            </Descriptions.Item>

            <Descriptions.Item label="Estado en Creasys">
              {renderCreasysStatus[query.data?.creasysStatus]}
            </Descriptions.Item>
            <Descriptions.Item label="Estado en Alpaca">
              {renderStatus[query.data?.alpacaStatus]}
            </Descriptions.Item>
            <Descriptions.Item label="Estado">
              {renderStatus[query.data?.status]}
            </Descriptions.Item>
          </Descriptions>

          <Tabs
            type="card"
            size="small"
            items={[
              {
                label: "Resumen",
                key: "summary",
                children: <Summary user={query.data}/>
              },
              {
                label: "Archivos",
                key: "files",
                children: <Result
                  status="404"
                  title="Aún no disponible"
                  subTitle="Lo sentimos, pronto estará disponible esta sección."
                />
              },
              {
                label: "Dinero",
                key: "money",
                children: <Result
                  status="404"
                  title="Aún no disponible"
                  subTitle="Lo sentimos, pronto estará disponible esta sección."
                />
              },
              {
                label: "Órdenes",
                key: "orders",
                children: <Result
                  status="404"
                  title="Aún no disponible"
                  subTitle="Lo sentimos, pronto estará disponible esta sección."
                />
              },
              {
                label: "Documentos",
                key: "documents",
                children: <Result
                  status="404"
                  title="Aún no disponible"
                  subTitle="Lo sentimos, pronto estará disponible esta sección."
                />
              }
            ]}
          />
        </Space>
        : query.isLoading ? null : <Result
          status="404"
          title="Usuario no encontrado"
          subTitle={`El usuario que buscas (${searchValue}) no existe. Por favor comprueba que ingresaste bien los datos.`}
        />}
      {hasComplianceRole ? <UserComplianceApproveDrawer
        userDetail={query.data}
        isOpen={isSubDrawerOpen}
        close={() => {
          query.refetch()
          setIsSubDrawerOpen(false)
        }}
      /> : null}
    </Spin>
  </Drawer>
}

export default UserDetailDrawer