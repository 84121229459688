import {CheckCircleFilled, MinusSquareFilled} from "@ant-design/icons";
import {Button, Card, Col, Layout, Row, Space, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import {Moment} from "moment";
import React, {ReactNode, useEffect, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {AdminApi} from "src/apis/admin.api";
import {MovementModel} from "src/models/movement.model";
import {formatToCurrencyUtil} from "src/utils/format-to-currency.util";
import {plainToInstanceMapUtil} from "src/utils/plain-to-instance-map.util";

const renderStatus: Record<string, ReactNode> = {
  received: <Tag>Recibido</Tag>,
  submitted: <Tag>Leído</Tag>,
  delivered: <Tag color="green">Entregado</Tag>,
  on_the_way: <Tag color="yellow">En Camino</Tag>,
  pending_on_the_way: <Tag color="yellow">En Camino</Tag>,
  pending_journal: <Tag color="yellow">Journal Pendiente</Tag>
}

const MovementsPage: React.FC = () => {
  const [movements, setMovements] = useState<Map<string, MovementModel>>(new Map())
  const [status, setStatus] = useState("received")
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined)

  const query = useQuery(
    ["listMovements", status, lastEvaluatedKey],
    () => AdminApi.movementsList(status, lastEvaluatedKey)
  )

  const apiCustomerOrdersMutation = useMutation({
    mutationFn: () => AdminApi.movementsApiCustomerOrders(),
    onSuccess: data => {
      console.log(data)
    }
  })

  useEffect(() => {
    if (query.data?.movements) {
      const newMovements = plainToInstanceMapUtil({
        key: "hash",
        instanceClass: MovementModel,
        objectArray: query.data.movements
      })
      setMovements(curr => new Map([...curr, ...newMovements]))
    }
  }, [query.data])

  const loadMore = () => setLastEvaluatedKey(query.data?.lastEvaluatedKey)

  const handleStatusChange = (value: string) => {
    if (status !== value) {
      setStatus(value)
      setLastEvaluatedKey(undefined)
      setMovements(new Map())
    }
  }

  const getApiCustomerOrders = () => {

  }

  const columns: ColumnsType<MovementModel> = [
    {
      title: "Fecha y hora",
      dataIndex: "createdAt",
      render: (createdAt: Moment) => createdAt.format("DD-MM-YYYY HH:mm")
    },
    {
      title: "# de identificación",
      dataIndex: "userIdNumber"
    },
    {
      title: "Monto",
      dataIndex: "amount",
      align: "right",
      render: (amount: number) => formatToCurrencyUtil(amount, "CLP")
    },
    {
      title: "Monto USD",
      dataIndex: "amountInUsd",
      align: "right",
      render: (amountInUsd: number) => formatToCurrencyUtil(amountInUsd, "USD")
    },
    {
      title: "Tipo de cambio",
      dataIndex: "usdToClp",
      align: "right",
      render: (usdToClp: number) => formatToCurrencyUtil(usdToClp, "CLP", 2)
    },
    {
      title: "Reportado a vector",
      dataIndex: "reported",
      align: "center",
      render: (reported: boolean) => reported ? <CheckCircleFilled style={{color: "green"}} /> : <MinusSquareFilled style={{color: "gray"}}/>
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (status: string) => renderStatus[status]
    }
  ]

  return (
    <Layout style={{backgroundColor: "transparent"}}>
      <Space direction="vertical" size="large">
        <Row gutter={16}>
          <Col span={24} lg={15}>
            <Card title="Estado" size="small">
              <Tag.CheckableTag
                onClick={() => handleStatusChange("submitted")}
                checked={status === "submitted"}
              >Leídos</Tag.CheckableTag>
              <Tag.CheckableTag
                onClick={() => handleStatusChange("received")}
                checked={status === "received"}
              >Recibidos</Tag.CheckableTag>
              <Tag.CheckableTag
                onClick={() => handleStatusChange("fx_posted")}
                checked={status === "fx_posted"}
              >Dashboard</Tag.CheckableTag>
              <Tag.CheckableTag
                onClick={() => handleStatusChange("pending_on_the_way")}
                checked={status === "pending_on_the_way"}
              >Pendientes en camino</Tag.CheckableTag>
              <Tag.CheckableTag
                onClick={() => handleStatusChange("on_the_way")}
                checked={status === "on_the_way"}
              >En camino</Tag.CheckableTag>
              <Tag.CheckableTag
                onClick={() => handleStatusChange("pending_journal")}
                checked={status === "pending_journal"}
              >Journal Pendiente</Tag.CheckableTag>
              <Tag.CheckableTag
                onClick={() => handleStatusChange("delivered")}
                checked={status === "delivered"}
              >Entregados</Tag.CheckableTag>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Card size="small" bodyStyle={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Space.Compact size="middle">
                <Button type="primary" onClick={() => apiCustomerOrdersMutation.mutate()}>Ordenes Api Customer</Button>
              </Space.Compact>
            </Card>
          </Col>
        </Row>
        <Table
          rowKey="hash"
          bordered
          sticky
          dataSource={Array.from(movements.values())}
          columns={columns}
          pagination={false}
          {...(query.data?.lastEvaluatedKey ? {footer: () => <Button onClick={loadMore} type="link">Cargar más</Button>} : {})}
        />
      </Space>
    </Layout>
  )
}

export default MovementsPage