import jwt from "jwt-decode"
import React, {PropsWithChildren, useEffect, useState} from "react";
import {AdminApi} from "../apis/admin.api";
import {ACCESS_TOKEN, REFRESH_TOKEN} from "./constants";

interface AuthInterface {
  user?: any
  isAuthenticated: boolean
  login: (email: string, password: string) => Promise<boolean>
  logout: () => void
  accessToken?: string
}

const AuthContext = React.createContext<AuthInterface | undefined>(undefined)

export const AuthProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [user, setUser] = useState<any>(null)
  const [accessToken, setAccessToken] = useState<string>("")
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN)

    if (token) {
      decodeUser()
      setAccessToken(token)
      setIsAuthenticated(true)
    }
  }, [])

  const decodeUser = () => {
    const token = localStorage.getItem(ACCESS_TOKEN)

    if (token) {
      const decoded: any = jwt(token)
      setUser(decoded.user)
    }
  }

  const login = async (email: string, password: string): Promise<boolean> => {
    try {
      const response = await AdminApi.login(email, password)
      setCredentials(response.accessToken, response.refreshToken, email)
      decodeUser()
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const setCredentials = (accessToken: string, refreshToken: string, email?: string) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken)
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
    setAccessToken(accessToken)
    setIsAuthenticated(true)
  }

  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(REFRESH_TOKEN)
    setUser(null)
    setAccessToken("")
    setIsAuthenticated(false)
  }

  return (
    <AuthContext.Provider value={{user, isAuthenticated, login, logout, accessToken}}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext) as AuthInterface