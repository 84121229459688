import {cloneDeep, intersection} from "lodash";
import {RouteModel} from "src/models/route.model";


export const filterRoutes = (routes: RouteModel[], userRoles: string[]): RouteModel[] => {
  const deepCloneRoutes = cloneDeep(routes)

  return deepCloneRoutes.filter(route => {
    if (userRoles.includes("master")) return true
    if (route.routes) {
      route.routes = filterRoutes(route.routes, userRoles)
      if (!route.routes?.length) return false
    }

    return (!!intersection(route.roles, userRoles).length || (!route.roles?.length && route.routes?.length))
  })
}

export const transformMenuItems = (routes: RouteModel[]): any => {
  return routes.map(({path, title, routes, divider}) => {
    if (divider) return {type: "divider"}

    return {
      key: path!.replace("/", ""),
      label: title,
      ...(routes?.length ? {children: transformMenuItems(routes)} : {})
    }
  })
}